import { Component, OnInit } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { NaoAssinantesV1Service } from '../services/nao-assinantes-v1.service';
import { AccountV1Model, AvaliacaoAccount } from 'src/app/auth/models/account-v1.model';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import Swal from 'sweetalert2';
import { FeatherModule } from 'angular-feather';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-tabela-clientes-avaliacao',
  standalone: true,
  imports: [
    SharedModule,
    FeatherModule,
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './tabela-clientes-avaliacao.component.html',
  styleUrl: './tabela-clientes-avaliacao.component.css'
})
export class TabelaClientesAvaliacaoComponent implements OnInit {
  //variáveis de ambiente
  listaClientes: {
    account: AccountV1Model,
    avaliacao: AvaliacaoAccount
  }[] = [];

  listaClientesPaginada: {
    account: AccountV1Model,
    avaliacao: AvaliacaoAccount
  }[] = [];

  periodoRestante: string;

  //variáveis de paginação
  limit: number = 10;
  paginaAtual: number = 1;
  totalCount: number = 0;
  skip: number = 0;


  //Variáveis do Navbar
  Menuoption: string = 'contratar-nao-permitido-acesso-a-outros-menus';
  Settingicon: boolean = false;
  linkRedirecionamento: string = '/home'

  //Variáveis do Footer
  hideFooter: boolean = true;

  //Variáveis do PageTitle
  title = 'Tabela de Clientes em Teste';

  constructor(
    private naoAssinantesV1Service: NaoAssinantesV1Service,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.getAccountsEmAvaliacao();
  }

  /**
   * Função utilizada para fazer um get nas contas em avaliação
   * no banco de dados
   */
  async getAccountsEmAvaliacao() {
    this.loaderService.startLoader(false);
    await this.naoAssinantesV1Service
      .getAccountsEmAvaliacao()
      .subscribe({
        next: resultado => {
          if (resultado.success) {
            //Atribuindo à listaClientes o resultado da requisição
            this.listaClientes = resultado.data;
            this.listaClientes.forEach((cliente, index) => {
              //Avaliando tipo de conta do usuario
              if (
                /**
                 * Se o usuário não estiver em teste ou em reteste
                 * ou se o usuário for manager ou parceiro,
                 * ele será removido da tabela
                 * */
                !cliente.avaliacao.isTeste && !cliente.avaliacao.isReteste
                || (cliente.account.isManager || cliente.account.isParceiro)
                || (cliente.avaliacao.isManager || cliente.avaliacao.isParceiro)
              ) {
                this.listaClientes.splice(index, 1);
              } else {
                /**
                 * Se ele estiver em teste ou reteste, então será avaliado
                 * o período restante da avaliação
                 */
                cliente.avaliacao.periodoRestante = this.calcularPeriodoRestante(cliente.avaliacao?.dtFimAvaliacao);
              }
            });

            //ordenando a listaClientes por ordem crescente, do nome
            this.listaClientes.sort((a, b) => a.avaliacao.dtFimAvaliacao < b.avaliacao.dtFimAvaliacao ? 1 : -1);
            this.totalCount = this.listaClientes.length;

            this.listaClientesPaginada = this.listaClientes.slice(0, this.limit);
          } else {
            Swal.fire(
              resultado.titulo,
              resultado.message,
              'error'
            )
          }
          this.loaderService.stopLoader();
        },
        error: err => {
          this.loaderService.stopLoader();
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
        }
      })
  }

  /**
   * Função responsável por calcular tempo restante
   * da avaliação/teste do usuário
   * @param dataFimAvaliacaoStr a data em que a avaliação finaliza, formatada em ISO
   * @returns O texto contendo o período restante em dias, ou em horas/minutos, ou que o período foi finalizado.
   */
  calcularPeriodoRestante(dataFimAvaliacaoStr: string) {
    const dataAtual = new Date();
    const dataFimAvaliacao = new Date(dataFimAvaliacaoStr);
    const diferencaTempo = dataFimAvaliacao.getTime() - dataAtual.getTime();

    const diasRestantes = Math.floor(diferencaTempo / (1000 * 60 * 60 * 24));
    const horasRestantes = Math.floor((diferencaTempo % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutosRestantes = Math.floor((diferencaTempo % (1000 * 60 * 60)) / (1000 * 60));

    if (diasRestantes == 0) {
      /** Se a quantidade de dias for igual a 0 */
      return `${horasRestantes} hora(s) e ${minutosRestantes} minuto(s)`;
    } else if (diasRestantes > 0) {
      /** Se a quantidade de dias for maior que 0 */
      return `${diasRestantes} dia(s)`;
    } else {
      return `Período de Teste/Reteste finalizado em ${dataFimAvaliacao.toLocaleDateString()}`
    }
  }

  /**
   * Função utilizada para definir o tipo de avaliação
   * do cliente, retornando um texto
   * @param cliente o cliente a ser avaliado
   * @returns Teste se isTeste == true, ou Reteste se isReteste == true
   */
  definindoTipoAvaliacao(
    cliente: {
      account: AccountV1Model,
      avaliacao: AvaliacaoAccount
    }
  ) {
    if (cliente.avaliacao.isTeste!) {
      return 'Teste';
    }
    if (cliente.avaliacao.isReteste!) {
      return 'Reteste';
    }
  }

  /**
   * Altera a quantidade de itens exibidos por página.
   *
   * Esta função realiza os seguintes passos:
   * 1. Redefine a variável `skip` para 0.
   * 2. Define a variável `paginaAtual` como 1.
   * 3. Atualiza a lista de centros de custo paginados (`listaClientesPaginada`) para exibir apenas os itens dentro do intervalo definido por `skip` e `limit`.
   *
   * @method
   */
  alterarQtdItens() {
    const copiaListaCompleta = [...this.listaClientes];
    this.skip = 0;
    this.paginaAtual = 1;

    this.listaClientesPaginada = copiaListaCompleta.slice(
      this.skip,
      this.skip + this.limit
    );
  };

  /**
   * Define o valor de `skip` para a paginação e atualiza a lista paginada de rubricas.
   *
   * Esta função realiza os seguintes passos:
   * 1. Verifica se a página atual é menor ou maior que a página do evento e ajusta o `skip` adequadamente.
   * 2. Atualiza a página atual (`paginaAtual`).
   * 3. Define a lista paginada de rubricas (`listaClientesPaginada`) com base no valor de `skip` e `limit`.
   *
   * @param {number} event - O número da nova página.
   * @method
   */
  definindoSkipPaginate(event: number) {
    const copiaListaCompleta = [...this.listaClientes];
    if (this.paginaAtual < event) {
      this.skip += this.limit;
    } else if (this.paginaAtual > event) {
      this.skip -= this.limit;
    }
    this.paginaAtual = event;

    this.listaClientesPaginada = copiaListaCompleta.slice(
      this.skip,
      this.skip + this.limit
    );
  }
}
